@import "/home/ubuntu/agravah/assets/css/variables/colors.scss";


body, html {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif;
}

hr {
    max-width: 50px;
    border-width: 3px;
    border-color: $themeColor;
}

hr.light {
    border-color: #fff;
}

a {
    color: $themeColor;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}

a:hover {
    color: $themeColor;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.bg-primary {
    background-color: $themeColor !important;
}

.bg-dark {
    background-color: #212529 !important;
}

.text-faded {
    color: rgba(255, 255, 255, 0.7);
}

section {
    padding: 8rem 0;
}

.section-heading {
    margin-top: 0;
}

::-moz-selection {
    color: #fff;
    background: #212529;
    text-shadow: none;
}

::selection {
    color: #fff;
    background: #212529;
    text-shadow: none;
}

img::selection {
    color: #fff;
    background: transparent;
}

img::-moz-selection {
    color: #fff;
    background: transparent;
}

body {
    -webkit-tap-highlight-color: #212529;
}

#mainNav {
    border-bottom: 1px solid rgba(33, 37, 41, 0.1);
    background-color: #fff;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}

#mainNav .navbar-brand {
    font-weight: 700;
    text-transform: uppercase;
    color: $themeColor;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

#mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
    color: $themeColor;
}

#mainNav .navbar-nav>li.nav-item>a.nav-link, #mainNav .navbar-nav>li.nav-item>a.nav-link:focus {
    font-size: .9rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #212529;
}

#mainNav .navbar-nav>li.nav-item>a.nav-link:hover, #mainNav .navbar-nav>li.nav-item>a.nav-link:focus:hover {
    color: $themeColor;
}

#mainNav .navbar-nav>li.nav-item>a.nav-link.active, #mainNav .navbar-nav>li.nav-item>a.nav-link:focus.active {
    color: $themeColor !important;
    background-color: transparent;
}

#mainNav .navbar-nav>li.nav-item>a.nav-link.active:hover, #mainNav .navbar-nav>li.nav-item>a.nav-link:focus.active:hover {
    background-color: transparent;
}

@media (min-width: 992px) {
    #mainNav {
        border-color: transparent;
        background-color: transparent;
    }
    #mainNav .navbar-brand {
        color: rgba(255, 255, 255, 0.7);
    }
    #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
        color: #fff;
    }
    #mainNav .navbar-nav>li.nav-item>a.nav-link {
        padding: 0.5rem 1rem;
    }
    #mainNav .navbar-nav>li.nav-item>a.nav-link, #mainNav .navbar-nav>li.nav-item>a.nav-link:focus {
        color: rgba(255, 255, 255, 0.7);
    }
    #mainNav .navbar-nav>li.nav-item>a.nav-link:hover, #mainNav .navbar-nav>li.nav-item>a.nav-link:focus:hover {
        color: #fff;
    }
    #mainNav.navbar-shrink {
        border-bottom: 1px solid rgba(33, 37, 41, 0.1);
        background-color: #fff;
    }
    #mainNav.navbar-shrink .navbar-brand {
        color: $themeColor;
    }
    #mainNav.navbar-shrink .navbar-brand:focus, #mainNav.navbar-shrink .navbar-brand:hover {
        color: $themeColor;
    }
    #mainNav.navbar-shrink .navbar-nav>li.nav-item>a.nav-link, #mainNav.navbar-shrink .navbar-nav>li.nav-item>a.nav-link:focus {
        color: #212529;
    }
    #mainNav.navbar-shrink .navbar-nav>li.nav-item>a.nav-link:hover, #mainNav.navbar-shrink .navbar-nav>li.nav-item>a.nav-link:focus:hover {
        color: $themeColor;
    }

    #mainNav.navbar-white {
        border-bottom: 1px solid rgba(33, 37, 41, 0.1);
        background-color: #fff;
    }
    #mainNav.navbar-white .navbar-brand {
        color: $themeColor;
    }
    #mainNav.navbar-white .navbar-brand:focus, #mainNav.navbar-white .navbar-brand:hover {
        color: $themeColor;
    }
    #mainNav.navbar-white .navbar-nav>li.nav-item>a.nav-link, #mainNav.navbar-white .navbar-nav>li.nav-item>a.nav-link:focus {
        color: #212529;
    }
    #mainNav.navbar-white .navbar-nav>li.nav-item>a.nav-link:hover, #mainNav.navbar-white .navbar-nav>li.nav-item>a.nav-link:focus:hover {
        color: $themeColor;
    }
}

header.masthead {
    padding-top: 10rem;
    padding-bottom: calc(10rem - 56px);
	background-color: black;
	background-image: url( /assets/img/bull.svg);
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

header.masthead hr {
    margin-top: 30px;
    margin-bottom: 30px;
}

header.masthead h1 {
    font-size: 2rem;
}

header.masthead p {
    font-weight: 300;
}

@media (min-width: 768px) {
    header.masthead p {
        font-size: 1.15rem;
    }
}

@media (min-width: 992px) {
    header.masthead {
        height: 100vh;
        min-height: 650px;
        padding-top: 0;
        padding-bottom: 0;
    }
    header.masthead h1 {
        font-size: 3rem;
    }
}

@media (min-width: 1200px) {
    header.masthead h1 {
        font-size: 4rem;
    }
}

@media (min-width: 1200px) {
    table {
        min-width: 440px;
    }
}

.service-box {
    max-width: 400px;
}

.portfolio-box {
    position: relative;
    display: block;
    max-width: 650px;
    margin: 0 auto;
}

.portfolio-box .portfolio-box-caption {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    color: #fff;
    background: $themeColorOp;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category, .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    padding: 0 15px;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    font-size: 30px;
}

.portfolio-box:hover .portfolio-box-caption {
    opacity: 1;
}

.portfolio-box:focus {
    outline: none;
}

@media (min-width: 768px) {
    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
        font-size: 20px;
    }
    .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
        font-size: 30px;
    }
}

.text-primary {
    color: $themeColor !important;
}

.btn {
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    border-radius: 300px;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.btn-xl {
    padding: 1rem 2rem;
}

.btn-primary {
    background-color: $themeColor;
    border-color: $themeColor;
}

.btn-primary-dark {
    background-color: $themeColorDark;
    border-color: $themeColorDark;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    color: #fff;
    background-color: $themeColorDark !important;
}

.btn-primary:active, .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem $themeColorDark !important;
}

.bull {
	background: url(/assets/img/bull.svg) no-repeat;
}

.bull.small {
	width: 70px;
	height: 70px;
	background-size: 70px;
	display: -webkit-inline-box;
    margin-bottom: 5px;
}

.bull.big {
	background-size: 740px;
	display: -webkit-inline-box;
    background-position: bottom;
}

.grain {
    background: url(/assets/img/grain.svg) no-repeat;
	width: 70px;
	height: 70px;
	background-size: 70px;
	display: -webkit-inline-box;
    margin-bottom: 5px;
}

.corn {
    background: url(/assets/img/corn.svg) no-repeat;
	width: 65px;
	height: 65px;
	background-size: 65px;
	display: -webkit-inline-box;
    margin-bottom: 5px;
}

.hay {
    background: url(/assets/img/hay.svg) no-repeat;
	width: 70px;
	height: 70px;
	background-size: 70px;
	display: -webkit-inline-box;
    margin-bottom: 5px;
}

.logo {
    background: url(/assets/img/agravah-logo.svg) no-repeat;
    width: 40px;
    height: 26px;
    background-size: 42px;
    display: -webkit-inline-box;
}

.logo-white {
    background: url(/assets/img/agravah-badge.svg) no-repeat;
    width: 80px;
    height: 52px;
    background-size: 82px;
    display: -webkit-inline-box;
}

.footer {
    background-color: $themeColor !important;
    padding: 4em 0;
}
.footer i {
    color: white;
}

.footer p, .footer a {
    color: rgba(255, 255, 255, 0.7)
}

.contact-badge {
    color: $themeColor;
    padding-right: 5px;
}

#contacts {
    padding: 8rem 0 4rem 0;
}

#contacts .text-center {
    font-size: 13px;
}

.info-icons .text-center {
    cursor: pointer;
}

#portfolio {
        font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}
#portfolio .birth {
    position: absolute;
    right: 10px;
    top: 5px
}


#portfolio .price-vat {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 12px;
}


#portfolio .price {
    position: absolute;
    right: 10px;
    bottom: 30px;
}


#portfolio .score {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.popup-gallery {
    border: 1px solid white;
}

@media (max-width: 414px) {
    .bull.big {
	    background-size: 320px;
	    display: -webkit-inline-box;
    }
    .portfolio-box .portfolio-box-caption {
        opacity: 100;
    }

}

@media (min-width: 414px) {
    .bull.big {
	    background-size: 410px;
	    display: -webkit-inline-box;
    }
}

@media (min-width: 990px) {
    .bull.big {
	    background-size: 800px;
	    display: -webkit-inline-box;
    }
}

@media (min-width: 768px) {
    header.masthead p {
    padding: 0 85px;
    }
}

@media (min-width: 1200px) {
    .mb-auto, .my-auto {
        margin-bottom: 225px!important;
    }
}